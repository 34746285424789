<template>
  <div class="div_wxLogin">
    <h3 class="h3_title">微信登录</h3>
    <h5 class="h5_title">微信扫一扫，关注公众号登录</h5>
    <div class="qrcode-box">
      <img v-show="wxUrl != ''" class="qrcode" :src="wxUrl" alt="" />
      <div
        v-show="refresh.show"
        class="div_refresh cp_us"
        @click="refreshQrCode()"
      >
        <i class="iconfont">&#xe75f;</i>
        <span>二维码已过期<br />点击刷新重新获取</span>
      </div>
    </div>
    <p class="p_explain">
      登录/注册表示同意 <span class="t_color cp_us">服务协议</span> 和
      <span class="t_color cp_us">隐私条款</span>
    </p>
    <div class="wx-login" @click="router.replace({ name: 'login' })">
      <i class="iconfont">&#xe70e;</i>
      手机号登录
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

import { getQRCode, wXFOALogin } from "@/api/api.js";

import { goBackToSource } from "@/utils/index";

const router = useRouter();

//微信二维码
const wxUrl = ref("");
//微信登录数据
let data = reactive({});
//微信登录定时器
let timeout = null;

let refresh = ref({
  show: false,
  time: 100, //100秒刷新一次
  nowTime: 0,
});

getWxQrCode(() => {
  getWxLogin();
});

//获取微信二维码
function getWxQrCode(callBack) {
  getQRCode().then((res) => {
    if(res.code == 200 && res.data){
      wxUrl.value = res.data.ticketUrl;
      data = res.data;
      callBack();
    }else {
      getWxQrCode(callBack)
    }
  });
}

//获取微信登录数据
function getWxLogin() {
  timeout = setInterval(() => {
    if (refresh.value.nowTime >= refresh.value.time) {
      clearInterval(timeout);
      timeout = null;
      refresh.value.show = true;
      return;
    }
    refresh.value.nowTime += 1;
    wXFOALogin(data.ticket).then((res) => {
      if (res.code == 200) {
        clearInterval(timeout);
        timeout = null;
        ElMessage({
          icon: "SuccessFilled",
          type: "success",
          message: "登录成功",
        });
        localStorage.setItem("wxLogin", JSON.stringify(res.data));
        setTimeout(() => {
          if (res.data.newUser) {
            router.push({
              name: "setPhone",
            });
          } else {
            localStorage.clear();
            goBackToSource(res.data.token);
          }
        }, 1000);
      }
    });
  }, 1000);
}

function refreshQrCode() {
  refresh.value.nowTime = 0;
  refresh.value.show = false;
  getWxQrCode(() => {
    getWxLogin();
  });
}

onUnmounted(() => {
  if (timeout) {
    clearInterval(timeout);
    timeout = null;
  }
});
</script>

<style lang='scss' scoped>
.div_wxLogin {
  padding-top: 40px;
  text-align: center;
  .h5_title {
    color: #33a396;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .qrcode-box {
    width: 220px;
    height: 220px;
    padding: 20px;
    margin: 0 auto;
    background: #fafafa;
    border-radius: 18px 18px 18px 18px;
    text-align: center;
    position: relative;
    .qrcode {
      width: 220px;
      height: 220px;
      border-radius: 18px 18px 18px 18px;
    }
    .div_refresh {
      width: 100%;
      height: 100%;
      border-radius: 18px;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      i {
        font-size: 64px;
      }
      span {
        font-size: 18px;
        margin-top: 18px;
      }
    }
  }
  .p_explain {
    color: #1f2328;
    margin-top: 32px;
    text-align: center;
  }
  .wx-login {
    background: #f7f7f7;
    padding: 18px;
    font-size: 16px;
    line-height: 52px;
    border-radius: 0px 0px 20px 20px;
    cursor: pointer;
    margin-top: 40px;
  }
}
</style>
